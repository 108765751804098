import React, {
  ChangeEvent, useCallback, useEffect, useState,
} from 'react';
import moment from 'moment';

import { IFormFieldProps, useFormField } from '@cashnu/forms';
import { ILabelProps, Label } from './Label';

import './DateField.scss';

const isNumeric = (value: string | undefined) => {
  if (value === undefined) return false;
  return !/[^\d]/.test(value);
};
export interface IDateFieldProps {
}

interface IDate {
  day?: number;
  month?: number;
  year?: number;
}

export const DateField = ({
  id, label, disabled, onChange, required,
}: IFormFieldProps & ILabelProps & IDateFieldProps) => {
  const { get, set } = useFormField(id);
  const formValue = get();
  const [date, setDate] = useState<Date | undefined>(formValue ? moment('' + formValue).toDate() : undefined);
  const [dateValue, setDateValue] = useState<IDate>({
    day: date !== undefined ? moment(date).date() : undefined,
    month: date !== undefined ? moment(date).month() + 1 : undefined,
    year: date !== undefined ? moment(date).year() : undefined,
  });
  const [dayValue, setDayValue] = useState<string | undefined>(dateValue?.day !== undefined ? String(dateValue.day) : undefined);
  const [monthValue, setMonthValue] = useState<string | undefined>(dateValue?.month !== undefined ? String(dateValue.month) : undefined);
  const [yearValue, setYearValue] = useState<string | undefined>(dateValue?.year !== undefined ? String(dateValue.year) : undefined);
  const [isValid, setValid] = useState<boolean>(false);

  const _validateDate = useCallback(() => {
    const { day, month, year } = dateValue;

    if (day && month && year && year > 1900) {
      const dateConstruct = moment.utc();
      dateConstruct.set('year', year);
      dateConstruct.set('month', month - 1);
      dateConstruct.set('date', day);
      dateConstruct.startOf('day');
      if (dateConstruct.isValid() && dateConstruct.year() === year && dateConstruct.month() === month - 1 && dateConstruct.date() === day) {
        const dateValue = dateConstruct.toDate();
        return {
          changed: (date === undefined || !moment(date).isSame(dateValue, 'day')),
          valid: true,
          value: dateValue,
        };
      }
    }

    return { changed: (date !== undefined), valid: !required, value: undefined };
  }, [dateValue, required, date]);

  const _checkDate = () => {
    const validationResult = _validateDate();

    if (validationResult.changed) {
      if (onChange) onChange(moment(validationResult.value).toString());
    }
    if (validationResult.valid !== isValid && !validationResult.valid) {
      if (onChange) onChange(undefined);
    }
  };

  const _onChangeDay = (event: ChangeEvent<HTMLInputElement>) => {
    setDayValue(event.target.value);

    dateValue.day = isNumeric(event.target.value) ? parseInt(event.target.value) : undefined;
    setDateValue(dateValue);
    _checkDate();
  };

  const _onChangeMonth = (event: ChangeEvent<HTMLInputElement>) => {
    setMonthValue(event.target.value);

    dateValue.month = isNumeric(event.target.value) ? parseInt(event.target.value) : undefined;
    setDateValue(dateValue);
    _checkDate();
  };

  const _onChangeYear = (event: ChangeEvent<HTMLInputElement>) => {
    setYearValue(event.target.value);

    dateValue.year = isNumeric(event.target.value) ? parseInt(event.target.value) : undefined;
    setDateValue(dateValue);
    _checkDate();
  };

  useEffect(() => {
    const validation = _validateDate();
    if (validation.valid !== isValid) {
      setValid(validation.valid);
    }
    if (validation.changed) {
      setDate(validation.value);
    }
  }, [dayValue, monthValue, yearValue, isValid, _validateDate]);

  useEffect(() => {
    set(moment(date).toString());
  }, [id, date, isValid, set]);

  return (
    <div className={`field date-field${isValid ? '' : ' invalid'}`}>
      <Label id={id} label={label} required={required} valid={isValid} />
      <div className="date">
        <input id={`${id}_day`} name="day" placeholder="dag" value={dayValue || ''} onChange={_onChangeDay} disabled={disabled} />
        <input id={`${id}_month`} name="month" placeholder="maand" value={monthValue || ''} onChange={_onChangeMonth} disabled={disabled} />
        <input id={`${id}_year`} name="year" placeholder="jaar" value={yearValue || ''} onChange={_onChangeYear} disabled={disabled} />
      </div>
    </div>
  );
};
