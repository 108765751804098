import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { Button, Container } from 'reactstrap';
import {
  useErrorBoundary,
  useServices,
} from '@cashnu/services';
import {
  Header,
  STATE_REQUEST_COMMITTED,
} from '@cashnu/services';
import { ContentView } from '@cashnu/headless-content';

import {
  DateField,
  EmailField,
  Fields,
  FormProvider,
  IbanField,
  InputField,
  PhonenumberField,
} from '@cashnu/forms';
import {
  GenderField,
  Product, Section, withRequest,
} from '../../components';

import './RequestInputPage.scss';

export const RequestDetailForm = withRequest(({ requestCode, request, loading }) => {
  const { requestService } = useServices();
  const { throwError } = useErrorBoundary();
  const navigate = useNavigate();
  const [saving, setSaving] = useState(false);
  const [canSubmit, setCanSubmit] = useState(false);

  const _saveConsumer = async (consumer) => {
    const newRequest = { requestId: requestCode, ...consumer };

    setSaving(true);

    try {
      await requestService.saveDetails(newRequest);
      navigate(`/request/form/saved/${requestCode}`);
    } catch (e) {
      throwError(e);
      setSaving(false);
    }
  };

  const _onEnableSubmit = (value) => {
    setCanSubmit(value);
  };

  const _onSubmit = (consumer) => {
    const { state } = request;

    if (state >= STATE_REQUEST_COMMITTED) {
      // Goto ready
      navigate(`/request/ready/${requestCode}`);
    } else {
      // Save
      _saveConsumer(consumer);
    }
  };

  if (loading === true || request === undefined) return null;

  const { product } = request;

  return (
    <div className="confirm-page">
      <Header title="RequestDetailsForm.header" />
      <div className="page-content">
        <Product {...product} showShipping={false} />

        <div className="entry-form">
          <Container>
            <ContentView id="request-input" className="mt-4 page-info" />
            <FormProvider
              disabled={saving || request.state >= STATE_REQUEST_COMMITTED}
              data={request}
              onSubmit={_onSubmit}
              onEnableSubmit={_onEnableSubmit}
            >
              <ContentView id="detail-form" className="mt-4 page-info" />
              <Section title="Uw gegevens">
                <Fields>
                  <InputField id="lastname" required />
                  <InputField id="middlename" />
                  <InputField id="initials" required />
                  <DateField id="birthdate" required />
                  <GenderField id="gender" required />
                </Fields>
              </Section>
              <Section title="Contact">
                <Fields>
                  <PhonenumberField id="phoneNumber" required />
                  <EmailField id="email" required />
                  <IbanField id="bankAccountNumber" required />
                </Fields>
              </Section>
              <Section>
                <Button
                  disabled={!(canSubmit && !saving)}
                  color={canSubmit && !saving ? 'success' : null}
                  type="submit"
                >
                  <FormattedMessage id="next" />
                </Button>
              </Section>
            </FormProvider>
          </Container>
        </div>
      </div>
    </div>
  );
});
