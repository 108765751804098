/* eslint-disable max-len */
import React, { ReactElement } from 'react';
import { Route, Routes } from 'react-router-dom';

import { RequestProvider } from '../components';
import {
  ContactPage,
  DocumentSignPage,
  ErrorPage,
  HomePage,
  HowItWorksPage,
  ProductPage,
  PurchasePage,
  PurchaseSavedPage,
  RequestAddressForm,
  RequestConfirmPage,
  RequestDeliveryPage,
  RequestDetailForm,
  RequestDocumentsPage,
  RequestDocumentsSavedPage,
  RequestEmailConfirmationPage,
  RequestExpiredPage,
  RequestIdentificationForm,
  SignatureSavedPage,
} from '../pages';

import './Routes.scss';

export const ProductRoutes = (): ReactElement => (
  <RequestProvider>
    <Routes>
      <Route path="/product" element={<ProductPage />} />
      <Route path="/request/delivery/:requestCode" element={<RequestDeliveryPage />} />
      <Route path="/request/form/:requestCode" element={<RequestDetailForm />} />
      <Route path="/request/form/saved/:requestCode" element={<RequestEmailConfirmationPage />} />
      <Route path="/request/confirm/:consumerCode" element={<RequestConfirmPage />} />
      <Route path="/request/address/:requestCode" element={<RequestAddressForm />} />
      <Route path="/request/identification/:requestCode" element={<RequestIdentificationForm />} />
      <Route path="/request/documents/:requestCode" element={<RequestDocumentsPage />} />
      <Route path="/request/documents/saved/:requestCode" element={<RequestDocumentsSavedPage />} />

      <Route path="/document/sign/:requestCode" element={<DocumentSignPage />} />
      <Route path="/document/signed/:requestCode" element={<SignatureSavedPage />} />

      <Route path="/request/expired/:requestCode" element={<RequestExpiredPage />} />

      <Route path="/purchase/:requestCode" element={<PurchasePage />} />
      <Route path="/purchase/saved/:requestCode" element={<PurchaseSavedPage />} />

      <Route path="/how-it-works" element={<HowItWorksPage />} />
      <Route path="/contact" element={<ContactPage />} />
      <Route path="/error" element={<ErrorPage />} />
      <Route path="/" element={<HomePage />} />
    </Routes>
  </RequestProvider>
);

export const AppRoutes = (): ReactElement => (
  <div className="page-content">
    <ProductRoutes />
  </div>
);
