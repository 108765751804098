import React, { useCallback, useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import {
  useServices,
} from '@cashnu/services';
import { ContentView } from '@cashnu/headless-content';
import {
  DateField,
  FormProvider,
  InputField,
  NumericField,
  PostcodeField,
  useFormData,
} from '@cashnu/forms';
import { 
  IdentityChoiceField,
  Fields, Section } from '../../components';

import './EntryForm.scss';

export const AddressForm = () => {
  const { set, data: { address } } = useFormData();
  const { addressService } = useServices();
  const [ timer, setTimer ] = useState(undefined);

  const [ city, setCity ] = useState(address.city);
  const [ street, setStreet ] = useState(address.street);

  const findAddress = useCallback(async () => {
    const { postcode, houseNumber } = address;

    setTimer(undefined);

    const newAddress = await addressService
      .find(postcode, parseInt(houseNumber));
      console.log(newAddress);
    
    if (!newAddress) {
      setStreet(undefined);
      setCity(undefined);
    } else {
      setStreet(newAddress.street);
      setCity(newAddress.city);
    }
    set('address.street', newAddress?.street);
    set('address.city', newAddress?.city);
    set('address.latitude', newAddress?.latitude);
    set('address.longitude', newAddress?.longitude);
  }, [addressService, address, set]);

  const _onChange = useCallback((id, value) => {
    if (id !== 'address.postcode' && id !== 'address.houseNumber') return;

    if (timer) clearTimeout(timer);

    var newTimer = setTimeout(findAddress, 500);
    setTimer(newTimer);
  }, [findAddress, timer])

  const clearTimer = useCallback(() => {
    if (timer) clearTimeout(timer);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return clearTimer();
  }, [clearTimer]);

  return (
    <>
      <Section title="Adres">
        <Fields>
          <PostcodeField id="address.postcode" label="postcode" required onChange={(value) => _onChange("address.postcode", value)} />
          <NumericField id="address.houseNumber" label="houseNumber" required onChange={(value) => _onChange("address.houseNumber", value)} />
          <InputField id="address.extension" label="extension" />
          <InputField id="address.street" label="street" value={street} />
          <InputField id="address.city" label="city" value={city} />
        </Fields>
      </Section>
    </>
  );
}

const IdentificationForm = () => (
  <>
    <ContentView id="identification-form" className="mt-4 page-info" />
    <Section title="Identificatie">
      <IdentityChoiceField id="identificationType" required />
      <Fields>
        <InputField id="identificationNumber" required />
        <DateField id="identificationExpirationDate" required />
      </Fields>
    </Section>
  </>
);

export const EntryForm = ({ section, disabled, request, onPrevious, onChange, onSubmit, submitAllowed }) => {
  const [canSubmit, setCanSubmit] = useState(submitAllowed);

  const onCanSubmit = (value) => {
    setCanSubmit(value);
  };

  return (
    <FormProvider
      disabled={disabled}
      data={request}
      onChange={onChange}
      onSubmit={onSubmit}
      onEnableSubmit={onCanSubmit}>
      { (section === 1) ? <AddressForm /> : null }
      { (section === 2) ? <IdentificationForm /> : null }
      <Section>
        <Button
          disabled={!(canSubmit && submitAllowed)}
          color={canSubmit && submitAllowed ? 'success' : null}
          type="submit">
          <FormattedMessage id="next" />
        </Button>
      </Section>
    </FormProvider>
  );
};
