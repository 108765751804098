import React from 'react';

import { ContentPage, IRequestComponentProps, withRequest } from '../../components';

export const PurchaseSavedPage = withRequest(({ request }: IRequestComponentProps) => {
  if (!request) return null;

  const data = {
    emailAddress: request.email || '',
    initials: request.initials || '',
    lastname: request.lastname || '',
    middlename: request.middlename || '',
  };

  return (
    <ContentPage
      id="purchase-saved"
      data={data}
      title="PurchaseSavedPage.header"
    />
  );
});
