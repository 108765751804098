import React, {
  createContext, ReactElement, useCallback, useContext, useEffect, useState,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { safeFetch, useSettings } from '@cashnu/services';

export interface IProduct {
  title?: string;
  purchasePrice?: number;
  salesPrice?: number;
  shippingCosts?: number;
}

export interface IRequestIdentifiers {
  requestId?: string;
  requestCode?: string;
  consumerCode?: string;
}

export interface IRequestProduct {
  product: IProduct;
}

export interface IAddress {
  postcode?: string;
  houseNumber?: number;
  extension?: string;
  street?: string;
  city?: string;
}

export interface IRequestIdentity {
  lastname?: string;
  middlename?: string;
  initials?: string;
  birthdate?: Date;
  email?: string;
  bankAccountNumber?: string;
  address?: IAddress;
}

export interface IRequest
extends IRequestIdentifiers, IRequestProduct, IRequestIdentity {
  state: number;
}

type RequestContextData = {
  request?: IRequest;
  loading: boolean;
  ensureRequest?: (requestCode: string) => {};
  loadRequest?: (requestCode: string) => {};
};
export const RequestContext = createContext<RequestContextData>({ loading: true });

const httpGet = async <T extends unknown>(url: string, headers?: []): Promise<T> => {
  const response = await safeFetch(url, { method: 'get', headers });
  return await response.json() as Promise<T>;
};

type RequestProviderProps = {
  children: any;
};

export const RequestProvider = ({ children }: RequestProviderProps): ReactElement => {
  const navigate = useNavigate();
  const [request, setRequest] = useState<IRequest | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(true);
  const { apiUrl } = useSettings<{ apiUrl: string }>();

  const loadRequest = useCallback(async (requestCode: string) => {
    setRequest(undefined);
    setLoading(true);

    try {
      const loadedRequest: IRequest = await httpGet<IRequest>(`${apiUrl}/request/${requestCode}`);
      if (loadedRequest?.address !== undefined) {
        if (loadedRequest.address.houseNumber === 0) loadedRequest.address.houseNumber = undefined;
      }
      setRequest(loadedRequest);
    } catch (e) {
      navigate(`/request/expired/${requestCode}`);
    } finally {
      setLoading(false);
    }
  }, [apiUrl, navigate]);

  const ensureRequest = useCallback(async (requestCode: string) => {
    const loadRequired = requestCode && !(request && request.requestId === requestCode);
    if (!loadRequired) return;

    loadRequest(requestCode);
  }, [loadRequest, request]);

  return (
    <RequestContext.Provider value={{
      request, loading, ensureRequest, loadRequest,
    }}
    >
      {children}
    </RequestContext.Provider>
  );
};

export interface IRequestComponentProps {
  requestCode?: string;
  request?: IRequest;
  loading: boolean;
}

export const useRequest = () => useContext(RequestContext);

export const withRequest = <T extends object>(WrappedComponent: React.ComponentType<IRequestComponentProps>) => (props: T) => {
  const { requestCode } = useParams<'requestCode'>();
  const { request, loading, ensureRequest } = useContext(RequestContext);

  useEffect(() => {
    if (requestCode === undefined) return;
    if (ensureRequest === undefined) return;

    ensureRequest(requestCode);
  }, [ensureRequest, requestCode]);

  return (
    <WrappedComponent
      {...props}
      requestCode={requestCode}
      request={request}
      loading={loading}
    />
  );
};
