import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container } from 'reactstrap';
import { set } from 'lodash';
import { Header, formatMoney } from '@cashnu/services';
import { useServices } from '@cashnu/services';
import { ContentView } from '@cashnu/headless-content';
import { Product, withRequest } from '../../components';
import { EntryForm } from './EntryForm';

import './PurchasePage.scss';

export const PurchasePage = withRequest(({ request: initialRequest, requestCode, loading }) => {
  const navigate = useNavigate();
  const [canSubmit, setCanSubmit] = useState(false);
  const [request, setRequest] = useState(initialRequest || { address: {}, product: {} });
  const [product, setProduct] = useState(null);
  const [timer, setTimer] = useState(null);
  const { requestService, addressService } = useServices();

  console.log('p', initialRequest)
  const onCanSubmit = (value) => {
    if (canSubmit !== value) setCanSubmit(value);
  };

  const onChange = useCallback((id, value, valid, consumer) => {
    if (id !== 'address.postcode' && id !== 'address.houseNumber') return;

    if (!(consumer && consumer.address && consumer.address.postcode && consumer.address.houseNumber)) {
      if (request.address && (request.address.street !== undefined || request.address.city !== undefined)) {
        set(request, 'address.street', undefined);
        set(request, 'address.city', undefined);
      }
      return;
    }

    if (timer) clearTimeout(timer);

    if (!valid) return;

    setTimer(setTimeout(async () => {
      const newAddress = await addressService
        .find(consumer.address.postcode, parseInt(consumer.address.houseNumber));

      if (!newAddress) {
        set(request, 'address.street', undefined);
        set(request, 'address.city', undefined);
      } else {
        set(request, 'address.street', newAddress.street);
        set(request, 'address.city', newAddress.city);
      }
      setRequest({ request });
      setTimer(null);
    }, 500));
  }, [addressService, request, timer]);

  const onSubmit = async (data) => {
    const request = Object.assign({
      requestId: requestCode,
    }, data);

    await requestService.savePurchase(request);
    navigate(`/purchase/saved/${requestCode}`);
  }

  useEffect(() => {
    setRequest(initialRequest);
    if (initialRequest) {
      setProduct(initialRequest.product);
    }
  }, [initialRequest]);

  if (loading || !request) return null;

  const { title: productTitle, purchasePrice, shippingCosts } = product || {};

  if (!product) return null;

  const introData = {
    productTitle,
    purchasePrice: formatMoney(product.purchasePrice),
    shippingCosts: formatMoney(product.shippingCosts),
    sumAmount: formatMoney(purchasePrice + shippingCosts),
  };

  return (
    <div className='page purchase-page'>
      <Header title="PurchasePage.header" />
      <div className="page-content">
        <Product {...product} showShipping={true} />

        <div className="entry-form">
          <Container>
            <ContentView id="purchase-intro" data={introData} className="mt-4 page-info" />
            <EntryForm
              request={request}
              onChange={onChange}
              onSubmit={onSubmit}
              onCanSubmit={onCanSubmit}
            />
          </Container>
        </div>
      </div>
    </div>
  )
});
