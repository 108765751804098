import React, { ReactElement, useEffect, useState } from 'react';
import { Button, ButtonGroup } from 'reactstrap';

import { IFormFieldProps, ILabelProps, useFormField } from '@cashnu/forms';

import { Label } from './Label';

import './ChoiceField.scss';
import classNames from 'classnames';

interface IChoiceFieldProps {
  options?: string[];
  className?: string;
}

export const ChoiceField = ({
  id, label, onChange, disabled, options, required, className,
}: IFormFieldProps & IChoiceFieldProps & ILabelProps)
  : ReactElement | null => {
  const { get, set, isValid, setValid } = useFormField(id);

  const [selected, setSelected] = useState<number | undefined>(
    get() as number | undefined,
  );

  const onClick = (option: number): void => {
    if (selected === undefined || (selected !== option)) {
      setSelected(option);
      set(option);
      setValid(true);
    } else if (selected === option) {
      setSelected(undefined);
      set(option);
      setValid(!required);
    }
    if (onChange) onChange(String(option));
  };

  useEffect(() => {
    const valid = !(required && selected === undefined);

    if (valid !== isValid) {
      setValid(selected !== undefined);
    }
    if (selected !== undefined) {
      set(selected);
    }
  }, [selected, id, isValid, required, get, set, setValid]);

  if (!options) return null;

  return (
    <div className={classNames(
      'field',
      'choice-fields',
      className,
      isValid ? '' : 'invalid',
    )}
    >
      <Label
        id={id}
        label={label}
        valid={isValid}
        validation={false}
        required={required}
      />
      <ButtonGroup disabled={disabled} className="choice-field">
        {options.map((option, index) => (
          <Button
            key={option}
            disabled={disabled}
            color={selected === index ? 'primary' : undefined}
            onClick={() => onClick(index)}
          >
            {option}
          </Button>
        ))}
      </ButtonGroup>
    </div>
  );
};
