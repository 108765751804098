import React from 'react';

import { ContentPage, IRequestComponentProps, withRequest } from '../../components';

export const RequestExpiredPage = withRequest(({ request }: IRequestComponentProps) => {
  if (!request) return null;

  const data = {
    emailAddress: request.email || '',
    initials: request.initials || '',
    lastname: request.lastname || '',
    middlename: request.middlename || '',
  };

  return (
    <ContentPage
      id="request-expired"
      data={data}
      title="RequestExpiredPage.header"
    />
  );
});
