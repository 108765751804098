import React, { useEffect, useState } from 'react';
import { Button, ButtonGroup } from 'reactstrap';
import classNames from 'classnames';

import { IFormFieldProps, ILabelProps, useFormField } from '@cashnu/forms';

import './IdentityChoiceField.scss';

interface IIdentityChoiceProps {
  title: string;
  imageUrl: string[];
  selected: boolean;
  onClick: () => void;
}

const IdentityChoice = ({
  title, imageUrl, selected, onClick,
}: IIdentityChoiceProps) => (
  <Button className={classNames('identity-choice', selected ? 'selected' : null)} onClick={() => onClick()}>
    <img src={selected ? imageUrl[1] : imageUrl[0]} alt={title} />
    <label>{title}</label>
  </Button>
);

export const IdentityChoiceField = ({
  id, label, onChange, disabled, required, ...rest
}: IFormFieldProps & ILabelProps) => {
  const { get, set, isValid, setValid } = useFormField(id);

  const [selected, setSelected] = useState<number | undefined>(get() as number | undefined);

  const _onClick = (option: number) => {
    if (selected === undefined || (selected !== option)) {
      setSelected(option);
      set(option);
      setValid(true);
    } else if (selected === option) {
      setSelected(undefined);
      set(-1);
      setValid(false);
    }
    if (onChange) onChange(String(option));
  };

  useEffect(() => {
    const valid = !(required && selected === undefined);

    if (selected !== undefined) {
      set(selected);
    } else {
      set(-1);
    }
    setValid(valid);
  }, [selected, id, isValid, required, set, setValid]);

  const options = [
    { value: 0, title: 'Paspoort', imageUrl: ['/images/paspoort-blue.png', '/images/paspoort-white.png'] },
    {},
    { value: 1, title: 'ID Kaart', imageUrl: ['/images/idkaart-blue.png', '/images/idkaart-white.png'] },
    {},
    { value: 2, title: 'Rijbewijs', imageUrl: ['/images/rijbewijs-blue.png', '/images/rijbewijs-white.png'] },
  ];

  return (
    <div className={`identity-choice-field${isValid ? '' : ' invalid'}`}>
      <ButtonGroup disabled={disabled}>
        {options.map(({ value, title, imageUrl }, index) => {
          if (!title) {
            return <div key={index} className="identity-choice-spacer" />;
          }
          return (
            <IdentityChoice
              key={index}
              title={title}
              imageUrl={imageUrl}
              selected={selected === value}
              onClick={() => _onClick(value)}
            />
          );
        })}
      </ButtonGroup>
    </div>
  );
};
