import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  Carousel,
  CarouselItem,
} from 'reactstrap';

import './StartCarousel.scss';

const items = [
  {
    src: 'images/cashnu-header-main1.jpg',
    altText: '',
    caption: 'Snel geld vrijmaken om een rekening te betalen?'
  },
  {
    src: 'images/cashnu-header-main2.jpg',
    altText: '',
    caption: 'Onvoorziene kosten dekken'
  },
  {
    src: 'images/cashnu-header-main3.jpg',
    altText: '',
    caption: 'Nu die fantastische aanbieding pakken'
  }
];

export const StartCarousel = (props) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  }

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  }

  // const goToIndex = (newIndex) => {
  //   if (animating) return;
  //   setActiveIndex(newIndex);
  // }

  const slides = items.map((item) => {
    return (
      <CarouselItem
        className="carousel-item"
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.src}
      >
        <div className="carousel-item-container" style={{ "backgroundImage": 'url(' + item.src + ')' }}>
          <div className="carousel-item-content">
            <h3>{item.caption}</h3>
            <Link to="/product">
              <Button type="button">Start aanvraag</Button>
            </Link>
          </div>
        </div>
      </CarouselItem>
    );
  });

  return (
    <Carousel
      activeIndex={activeIndex}
      interval="15000"
      next={next}
      previous={previous}
    >
      {slides}
    </Carousel>
  );
}