import { useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useServices } from '@cashnu/services';

import { IAppServices } from '../../components/IAppServices';

import './RequestConfirmPage.scss';

export const RequestConfirmPage = (): null => {
  const { requestService } = useServices<IAppServices>();
  const { consumerCode } = useParams<'consumerCode'>();
  // const { throwError } = useErrorBoundary();
  const navigate = useNavigate();

  const confirmEmail = useCallback(async () => {
    try {
      const request = await requestService.confirmEmail(consumerCode!);
      navigate(`/request/address/${request.requestId}`);
    } catch (e) {
      // throwError(e);
    }
  }, [consumerCode, navigate, requestService]);

  useEffect(() => {
    confirmEmail();
  }, [confirmEmail, consumerCode]);

  return null;
};
