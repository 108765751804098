/* eslint-disable class-methods-use-this */
import React, { ReactElement } from 'react';
import { IntlProvider } from 'react-intl';
import { BrowserRouter, useNavigate } from 'react-router-dom';
import moment from 'moment';
import {
  AddressService,
  AuthenticationProvider,
  Compose,
  ErrorBoundaryProvider,
  IAuthenticationSettings,
  ServicesProvider,
  SettingsProvider,
  RequestAttachmentService,
  RequestService,
  useAuthentication,
  useSettings,
  FailedToFetchException,
} from '@cashnu/services';
import {
  ContentProvider,
} from '@cashnu/headless-content';
import { ReactNotifications, Store } from 'react-notifications-component';

import 'moment/locale/nl';

import { IAppServices } from './components/IAppServices';
import siteContent from './content/nl';
import messagesNl from './lang/nl.json';
import { AppRoutes } from './routes/Routes';
import { logger, LogLevel } from './utils/logger';
import {
  Footer, TopNavbar,
} from './components';

import 'bootstrap/dist/css/bootstrap.css';
import 'react-notifications-component/dist/theme.css';
import './App.scss';
import { ErrorProvider, useErrorContext } from './contexts';

moment.locale('nl-NL');
logger.setLevel(LogLevel.Warning);

interface IServiceSettings {
  apiUrl: string;
}

const useErorReport = () => {
  const navigate = useNavigate();
  const errorContext = useErrorContext();

  return (e: Error): never => {
    console.info('error', typeof e, e instanceof FailedToFetchException, e instanceof Error);
    navigate('/error');
    errorContext.setError(e);
    
    Store.addNotification({
      title: 'Error',
      message: e.message,
      type: 'danger',
      insert: "top",
      container: "top-right",
      animationIn: ['animated', 'fadeIn'],
      animationOut: ['animated', 'fadeOut'],
      dismiss: {
        duration: 5000,
        click: true,
        pauseOnHover: true,
        showIcon: true,
        onScreen: true
      }
    });
    throw e;
  }
}

const Main = (): ReactElement => {
  const { userManager } = useAuthentication();
  const settings = useSettings<IAppSettings>();
  const errorReport = useErorReport();

  if (userManager === undefined) return <></>;

  const configureServices = (
  ): IAppServices => ({
    addressService: new AddressService({ settings, userManager, errorReport }),
    requestService: new RequestService({ settings, userManager, errorReport }),
    // eslint-disable-next-line max-len
    requestAttachmentService: new RequestAttachmentService({ settings, userManager, errorReport }),
    // consumerService: new ConsumerService(settings, userManager),
    // contentService: new ContentService(settings, userManager),
  });

  return (
    <ErrorBoundaryProvider>
      <ServicesProvider configureServices={configureServices}>
        <TopNavbar />
        <AppRoutes />
        <Footer />
      </ServicesProvider>
    </ErrorBoundaryProvider>
  );
}

interface IAppSettings extends IAuthenticationSettings, IServiceSettings {
}

const contexts = [
  <BrowserRouter />,
  <ErrorProvider />,
  <SettingsProvider<IAppSettings> filename='/settings.json' />,
  <AuthenticationProvider alwaysGuest />,
  <IntlProvider locale="nl" messages={messagesNl} />,
  <ContentProvider content={siteContent} />,
];

const App = (): ReactElement => (
  <Compose components={contexts}>
    <ReactNotifications />
    <Main />
  </Compose>
);

export default App;
