import React, { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';

import './Header.scss';

export const Header = ({ title, values }: {title: string, values?: Record<string, React.ReactNode> }): ReactElement => (
  <div className="header">
    <h1>
      <FormattedMessage
        id={title}
        defaultMessage={title}
        values={values}
      />
    </h1>
  </div>
);
