import React from 'react';
import { ContentView } from '@cashnu/headless-content';

import { StartCarousel } from './StartCarousel';
import { HowItWorksBar } from './HowItWorksBar';
import { InstructionsBar } from './InstructionsBar';

import './HomePage.scss';

const Spacer = () => (
  <div className="spacer">
  </div>
);

export const HomePage = () => (
  <div className="home-page">
    <StartCarousel />
    <Spacer />
    <div className="high-energy">
      <div className="high-energy-image">
      </div>
      <div className="high-energy-content">
        <ContentView id="high-energy" />
      </div>
    </div>
    <Spacer />
    <HowItWorksBar />
    <Spacer />
    <InstructionsBar />
    <Spacer />
    <ContentView id="reviews" className="customer-reviews" />
  </div>
);
