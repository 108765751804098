import React, { useState } from 'react';
import { Button } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import {
  FormProvider,
  InputField,
  EmailField,
  PhonenumberField,
} from '@cashnu/forms';
import { DateField, GenderField, Fields, Section } from '../../components';
import { AddressForm } from '../RequestInputPage/EntryForm';

import './EntryForm.scss';

export const EntryForm = ({ request, onChange, onSubmit }) => {
  const [canSubmit, setCanSubmit] = useState(false);

  const onCanSubmit = (value) => {
    if (canSubmit !== value) setCanSubmit(value);
  };

  return (
    <FormProvider
      data={request}
      onChange={onChange}
      onSubmit={onSubmit}
      onEnableSubmit={onCanSubmit}>
      <Section title="Uw gegevens">
        <Fields>
          <InputField id="lastname" required />
          <InputField id="middlename" />
          <InputField id="initials" required />
          <DateField id="birthdate" required />
          <GenderField id="gender" required />
        </Fields>
      </Section>
      <Section title="Contact">
        <Fields>
          <PhonenumberField id="phoneNumber" required />
          <EmailField id="email" required />
        </Fields>
      </Section>
      <AddressForm />
      <Section>
        <Button
          disabled={!(canSubmit)}
          color={canSubmit ? 'success' : null}
          type="submit">
          <FormattedMessage id="next" />
        </Button>
      </Section>
    </FormProvider>
  );
};
