import React from 'react';
import { Button, Container } from 'reactstrap';
import { useServices } from '@cashnu/services';
import { Header } from '@cashnu/services';

import { ContentView } from '@cashnu/headless-content';
import { IRequestComponentProps, withRequest } from '../../components';

import './IdentificationPaymentPage.scss';
import { IAppServices } from '../../components/IAppServices';
import { get } from 'lodash';

export const IdentificationPaymentPage = withRequest(({ requestCode, request }: IRequestComponentProps) => {
  const { requestService } = useServices<IAppServices>();

  if (!request) return null;

  const data = {
    emailAddress: request.email || '',
    initials: request.initials || '',
    lastname: request.lastname || '',
    middlename: request.middlename || '',
    bankAccountNumber: request.bankAccountNumber || '',
  };

  const onPayNow = async () => {
    try {
      if (requestCode === undefined) return;
      const payment = await requestService
        .createIdentificationPayment(requestCode);
      const paymentUrl = get(payment, 'paymentUrl');
      if (typeof paymentUrl === 'string') window.location.href = paymentUrl;
    } catch (e) {
      // throwError(e);
    }
  };

  return (
    <div className="ideal-identification-page">
      <Header title="Identificatie betaling" />
      <Container>
        <ContentView id="identification-payment" data={data} />
        <Button color="primary" onClick={onPayNow}>
          Betaal nu
        </Button>
      </Container>
    </div>
  );
});
