import React from 'react';

import './Section.scss';

interface ISectionProps {
  title: string;
  children: any;
}

export const Section = ({ title, children }: ISectionProps) => (
  <div className="section">
    {title
      ? (
        <>
          <h2>{title}</h2>
          <hr />
        </>
      )
      : null}
    {children}
  </div>
);
