/* eslint-disable no-unused-vars */
// interface IConsole {
//   count(label?: string): void;
//   countReset(label?: string): void;

//   group(...label: any[]): void;
//   groupEnd(): void;

//   table(tabularData: any, properties?: ReadonlyArray<string>): void;

//   time(label?: string): void;
//   timeEnd(label?: string): void;
//   timeLog(label?: string, ...data: any[]): void;

//   debug(message?: any, ...optionalParams: any[]): void;
//   log(message?: any, ...optionalParams: any[]): void;
//   info(message?: any, ...optionalParams: any[]): void;
//   error(message?: any, ...optionalParams: any[]): void;
//   trace(message?: any, ...optionalParams: any[]): void;
//   warn(message?: any, ...optionalParams: any[]): void;

//   setLevel(level: LogLevel): void;
// }

export enum LogLevel {
  Trace = 1,
  Debug = 2,
  Information = 3,
  Warning = 4,
  Error = 5,
}

class Logger {
  private logLevel: LogLevel = LogLevel.Information;

  constructor() {
    this.logLevel = LogLevel.Information;
  }

  setLevel(level: LogLevel): void {
    this.logLevel = level;
  }

  group(...label: unknown[]): void {
    if (this.logLevel > LogLevel.Information) return;
    console.group(label);
  }

  groupEnd(): void {
    if (this.logLevel > LogLevel.Information) return;
    console.groupEnd();
  }

  count(label?: string): void {
    if (this.logLevel > LogLevel.Information) return;
    console.count.apply(console, [label]);
  }

  countReset(label?: string): void {
    if (this.logLevel > LogLevel.Information) return;
    console.countReset(label);
  }

  time(label?: string): void {
    if (this.logLevel > LogLevel.Information) return;
    console.time.apply(console, [label]);
  }

  timeEnd(label?: string): void {
    if (this.logLevel > LogLevel.Information) return;
    console.timeEnd(label);
  }

  timeLog(label?: string, ...data: unknown[]): void {
    if (this.logLevel > LogLevel.Information) return;
    console.timeLog(label, data);
  }

  log(message?: unknown, ...optionalParams: unknown[]): void {
    if (this.logLevel > LogLevel.Information) return;
    console.log(message, optionalParams);
  }

  info(message?: unknown, ...optionalParams: unknown[]): void {
    if (this.logLevel > LogLevel.Information) return;
    console.info(message, optionalParams);
  }

  error(message?: unknown, ...optionalParams: unknown[]): void {
    if (this.logLevel > LogLevel.Error) return;
    console.error(message, optionalParams);
  }

  debug(message?: unknown, ...optionalParams: unknown[]): void {
    if (this.logLevel > LogLevel.Debug) return;
    console.debug(message, optionalParams);
  }

  warn(message?: unknown, ...optionalParams: unknown[]): void {
    if (this.logLevel > LogLevel.Warning) return;
    console.warn(message, optionalParams);
  }

  trace(message?: unknown, ...optionalParams: unknown[]): void {
    if (this.logLevel > LogLevel.Trace) return;
    console.trace(message, optionalParams);
  }

  table(tabularData: unknown, properties?: ReadonlyArray<string>): void {
    if (this.logLevel > LogLevel.Information) return;
    console.table(tabularData, properties);
  }
}

export const logger = new Logger();
