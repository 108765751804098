import { IChildren } from '@cashnu/services';
import { createContext, ReactElement, useContext, useMemo, useState } from 'react';

interface IErrorContext {
  error?: Error;
  setError: (error: Error) => void;
}

const ErrorContext = createContext<IErrorContext>({ setError: () => {} });

export const useErrorContext = () => useContext(ErrorContext);

export const ErrorProvider = ({ children }: IChildren): ReactElement => {
  const [error, setError] = useState<Error>();

  const value = useMemo<IErrorContext>(() => ({
    error,
    setError,
  }), [error]);

  return (
    <ErrorContext.Provider value={value}>
      {children}
    </ErrorContext.Provider>
  )
}