import React, { useState } from 'react';
import { set } from 'lodash';
import { useNavigate } from 'react-router';
import { Button, Container } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { InputField, EmailField, PhonenumberField, FormProvider } from '@cashnu/forms';
import { Header, useServices } from '@cashnu/services';

import "./ContactPage.scss";

export const ContactPage = () => {
  const navigate = useNavigate();
  const { consumerService } = useServices();
  const [canSubmit, setCanSubmit] = useState(false);

  const onCanSubmit = (value) => {
    if (canSubmit !== value) setCanSubmit(value);
  };

  const onChange = (id, value, valid, data) => {
    if (!valid) return;
    set(data, id, value);
  }

  const onSubmit = async (data) => {
    await consumerService.sendContactForm(data);
    navigate('/');
  }

  return (
    <div className="contact-page">
      <Header title="ContactPage.header" />

      <Container>
        <div className="contact-content">
          <div className="contact-form">
            <h3><FormattedMessage id="ContactPage.intro" /></h3>
            <FormProvider
              data={{}}
              onSubmit={onSubmit}
              onCanSubmit={onCanSubmit}
              onChange={onChange}>
              <div className="side-by-side">
                <InputField id="name" required />
                <EmailField id="email" required />
              </div>
              <div className="side-by-side">
                <PhonenumberField id="phoneNumber" required />
                <InputField id="subject" required />
              </div>
              <div className="side-by-side question">
                <InputField id="question" required />
              </div>
              <Button
                disabled={!(canSubmit)}
                color={canSubmit ? 'success' : null}
                type="submit">
                <FormattedMessage id="send" />
              </Button>
            </FormProvider>
          </div>

          <div className="spacer">
          </div>

          <div className="contact-info">
            <div></div>
            <div className="contact-info-section">
              <i className="fa fa-2x fa-phone"></i>
              <div>
                <p>Bel ons altijd</p>
                <p><a href="tel:+31 85 401 65 07">+31 85 401 65 07</a></p>
              </div>
            </div>
            <div><hr /></div>
            <div className="contact-info-section">
              <i className="fa fa-2x fa-envelope"></i>
              <div>
                <p>E-mail ons</p>
                <p><a href="mailto:support@cashnu.nl">support@cashnu.nl</a></p>
              </div>
            </div>
            <div></div>
          </div>
        </div>
      </Container>
    </div>
  );
}
