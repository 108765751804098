import React from 'react';
import { FormattedMessage } from 'react-intl';

export interface IIndicatorProps {
  id: string;
  required?: boolean;
  validation?: boolean;
  valid: boolean;
}

export interface ILabelProps extends IIndicatorProps {
  id: string;
  label?: string;
}

const Indicator = ({
  id, required, validation, valid,
}: IIndicatorProps) => {
  if (validation) {
    if (valid === false) return <i className="fa fa-s fa-exclamation" />;
    return <i className="fa fa-s fa-check" />;
  }

  if (required) {
    return <i className="fa fa-s fa-asterisk" />;
  }

  return null;
};

export const Label = ({ id, label, ...rest }: ILabelProps) => (
  <label htmlFor={id}>
    <FormattedMessage id={label || id} defaultMessage={label || id} />
    <div className="indicator"><Indicator id={id} {...rest} /></div>
  </label>
);
