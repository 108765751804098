import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, ButtonGroup, Container } from 'reactstrap';
import { Navigate, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { LoadingState, useErrorBoundary, useServices } from '@cashnu/services';
import { Header, STATE_REQUEST_COMMITTED } from '@cashnu/services';
import { ContentView } from '@cashnu/headless-content';
import { Store } from 'react-notifications-component';

import {
  FileField,
  Section,
  withRequest,
} from '../../components';
import { identificationTypeText } from '../../utils/helpers';

import './RequestDocumentsPage.scss';

const MAX_UPLOAD_SIZE = 8 * 1024 * 1024;

const notifyUploadToBig = () => {
  Store.addNotification({
    title: 'Document upload',
    message: 'Uw bestand is groter dan 8MB. 8MB is de maximaal toegestane grote voor bestanden die door CashNu geaccepteerd worden. Probeer uw bestand te verkleinen voordat u het instuurt.',
    type: 'danger',
    insert: "top",
    container: "top-right",
    animationIn: ['animated', 'fadeIn'],
    animationOut: ['animated', 'fadeOut'],
    dismiss: {
      duration: 5000,
      click: true,
      pauseOnHover: true,
      showIcon: true,
      onScreen: true
    }
  });
}

const convertAttachmentState = (requestAttachmentStatus) => {
  if (!requestAttachmentStatus) return [];
  const attachmentStatus = [];
  attachmentStatus['idfile'] = requestAttachmentStatus['idFile'];
  attachmentStatus['transcript1'] = requestAttachmentStatus['transcript1'];
  attachmentStatus['transcript2'] = requestAttachmentStatus['transcript2'];
  attachmentStatus['transcript3'] = requestAttachmentStatus['transcript3'];
  return attachmentStatus;
}

export const RequestDocumentsPage = withRequest(({ request, loading }) => {
  const { requestService, requestAttachmentService } = useServices();
  const navigate = useNavigate();
  const { throwError } = useErrorBoundary();
  const [ isConsent, setConsent ] = useState(false);
  const [ attachmentStatus, setAttachmentStatus ] = useState([]);
  const [ allAttachmentsSaved, setAllAttachmentsSaved ] = useState(false);
  const requestCode = request?.requestId;

  const checkAttachments = useCallback(() => {
    const allAttachmentsSaved =
      (attachmentStatus['idfile'] === true)
      && (attachmentStatus['transcript1'] === true)
      && (attachmentStatus['transcript2'] === true)
      && (attachmentStatus['transcript3'] === true);
    setAllAttachmentsSaved(allAttachmentsSaved);
  }, [attachmentStatus]);

  useEffect(() => {
    checkAttachments();
  }, [checkAttachments]);

  useEffect(() => {
    setAttachmentStatus(convertAttachmentState(request?.attachmentStatus));
  }, [request?.attachmentStatus]);

  if (request === undefined || loading) return <LoadingState />;

  const formValid = useMemo(() => {
    return allAttachmentsSaved && isConsent;
  }, [allAttachmentsSaved, isConsent]);

  const saveAttachment = useCallback(async (id, files) => {
    try {
      const upload = files[0];

      if (upload.size > MAX_UPLOAD_SIZE) {
        notifyUploadToBig();
        return false;
      }

      attachmentStatus[id] = false;
      setAttachmentStatus(attachmentStatus);

      await requestAttachmentService.saveAttachment(
        requestCode,
        id,
        upload,
      );

      attachmentStatus[id] = true;
      setAttachmentStatus(attachmentStatus);
      return true;

    } catch(e) {
      console.error('Saving attachment failed', requestCode, id, e);
      throwError(e);
    } finally {
      checkAttachments();
    }
  }, [attachmentStatus, checkAttachments, requestAttachmentService, requestCode, throwError]);

  const commitRequest = async () => {
    try {
      await requestService.commit(requestCode);

      navigate(`/request/documents/saved/${requestCode}`);
    } catch (e) {
      console.error(e);
      throwError(e);
      this.setState({ savingProgress: 0, saving: false, error: e.message });
    }
  }

  const submit = (e) => {
    e.preventDefault();

    if (!formValid) return;

    commitRequest();
  }

  const identificationType = () => {
    if (!request) return '';
    return identificationTypeText(request.identificationType);
  }

  if (request.state >= STATE_REQUEST_COMMITTED) {
    return <Navigate to={`/request/documents/saved/${requestCode}`} />;
  }

  return (
    <div className="confirm-page">
      <Header title="RequestFilePage.header" />
      <Container>
        <form onSubmit={submit}>
          <Section>
            <ContentView
              id="request-documents"
              className="mt-4 page-info"
              data={{
                identificationType: identificationType(),
                bankAccountNumber: request.bankAccountNumber,
              }}
            />
            <div className="files">
              <FileField id="idfile" save={async (id, files) => await saveAttachment(id, files)} saved={request.attachmentStatus.idFile}>
                Kopie van uw
                {' '}
                {identificationType()}
              </FileField>
              <FileField id="transcript1" save={async (id, files) => await saveAttachment(id, files)} saved={request.attachmentStatus.transcript1}>
                Kopie van bankafschrift 1
                <br />
                {moment().add(-3, 'month').format('MMMM YYYY')}
              </FileField>
            </div>
            <div className="files">
              <FileField id="transcript2" save={async (id, file) => await saveAttachment(id, file)} saved={request.attachmentStatus.transcript2}>
                Kopie van bankafschrift 2
                <br />
                {moment().add(-2, 'month').format('MMMM YYYY')}
              </FileField>
              <FileField id="transcript3" save={async (id, file) => await saveAttachment(id, file)} saved={request.attachmentStatus.transcript3}>
                Kopie van bankafschrift 3
                <br />
                {moment().add(-1, 'month').format('MMMM YYYY')}
              </FileField>
            </div>
          </Section>
          <Section>
            <div className="mb-3">
              <a
                href="https://cdn.cashnu.nl/pdf/Algemene voorwaarden - Moneco 2022.pdf"
                target="_blank"
                rel="noreferrer"
              >
                Lees hier de algemene voorwaarden
              </a>
            </div>
            <label htmlFor="consent" className="ml-2">
              <input
                type="checkbox"
                id="consent"
                name="consent"
                value={isConsent ? "on" : "off"}
                onChange={e => setConsent(e.target.checked)}
                className="mr-2"
              />
              Klik hier voor akkoord op de
              algemene voorwaarden van CashNu.nl *
            </label>
          </Section>
          <Section>
            <ButtonGroup>
              <Button
                disabled={!formValid}
                color={formValid ? 'success' : null}
                type="submit">
                Versturen
              </Button>
            </ButtonGroup>
          </Section>
        </form>
      </Container>
    </div>);
});
