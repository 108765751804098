import React, { MouseEvent } from 'react';
import { Button } from 'reactstrap';
import { formatMoney } from '@cashnu/services';

import './Product.scss';

export type ProductProps = {
  title: string;
  product: string;
  purchasePrice: number;
  salesPrice: number;
  shippingCosts: number;
  readonly: boolean;
  showShipping: boolean;
};

export const Product = ({
  title, product: productId, purchasePrice, salesPrice, shippingCosts, readonly, showShipping = true, onClick,
}: ProductProps & { onClick: (event: Event) => void | undefined; }) => {
  function click<T>(event: MouseEvent<T>) {
    event.preventDefault();
    event.stopPropagation();
    if (onClick !== undefined) onClick(event.nativeEvent);
  }

  return (
    <div className={`product-component${onClick !== undefined ? ' selectable' : ''}`} onClick={click}>
      <h3>{title}</h3>
      <div className="product-content">
        <img src={`/images/coin${productId}.png`} alt="coin" />
        <div className="my-2">
          <div className="salesPrice mb-2">
            U koopt de munt voor
            &nbsp;
            <b>
              &euro;
              {formatMoney(salesPrice)}
            </b>
          </div>
          <div className="request mb-2">
            U verkoopt de munt voor
            &nbsp;
            <b>
              &euro;
              {formatMoney(purchasePrice)}
            </b>
          </div>
          { showShipping ? (
            <div className="shipping">
              Verzendkosten
              &nbsp;
              <b>
                &euro;
                {formatMoney(shippingCosts)}
              </b>
            </div>
          ) : null}
        </div>
      </div>
      { onClick !== undefined ? (
        <Button
          color={!readonly ? 'primary' : 'disabled'}
          disabled={readonly}
          onClick={click}
        >
          Kies
          <br />
          {title}
        </Button>
      ) : null }
    </div>
  );
};
