import React from 'react';

import './Fields.scss';

interface IFieldsProps {
  children: any;
}

export const Fields = ({ children }: IFieldsProps) => (
  <div className="fields">
    {children}
  </div>
);
