import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, ButtonGroup, Container } from 'reactstrap';
import SignatureCanvas from './ReactSignatureCanvas'
import { useMediaQuery } from 'react-responsive';
import { useErrorBoundary, useServices, Error, Header, LoadingState } from '@cashnu/services';
import { ContentView } from '@cashnu/headless-content';
import { withRequest } from '../../components';

import './DocumentSignPage.scss';

export const DocumentSignPage = withRequest(({ request }) => {
  const navigate = useNavigate();
  const { requestService } = useServices();
  const { throwError } = useErrorBoundary();
  const { requestCode } = useParams();
  const [canvasRef, setCanvasRef] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [hasSignature, setHasSignature] = useState(undefined);
  const [isEmpty, setIsEmpty] = useState(true);
  const [error, setError] = useState(undefined);

  const isMobile = useMediaQuery({ query: '(max-width: 768px)'})
  const isSmallMobile = useMediaQuery({ query: '(max-width: 300px)'})

  useEffect(() => {
    console.log(1);
    const loadSignatureInfo = async () => {
      try {
        var result = await requestService.hasSignature(requestCode);
        setHasSignature(result);
        if (result) {
          navigate(`/document/signed/${requestCode}`);
        }
      } catch (e) {
        console.error(e);
        setError(e);
        // throwError(e);
      } finally {
        setLoading(false);
      }
    }
    loadSignatureInfo();
  }, [navigate, requestCode, requestService, setHasSignature, throwError]);

  const onEnd = useCallback(() => {
    setIsEmpty(canvasRef ? canvasRef.isEmpty() : true);
  }, [canvasRef]);

  const clear = () => {
    if (canvasRef) {
      canvasRef.clear();
      setIsEmpty(true);
    }
  }

  const save = async () => {
    try {
      const signature = canvasRef
        .getTrimmedCanvas()
        .toDataURL('image/png');

      await requestService.saveSignature(
        requestCode,
        signature
      );

      navigate(`/document/signed/${requestCode}`);
    } catch (e) {
      throwError(e);
    }
  }

  const cancel = () => {
  }

  if (error) return <Error name={error.name} message={error.message} title={error.title} detail={error.detail} />;
  if (request === undefined || loading || hasSignature === undefined) return <LoadingState />;

  const data = {
    initials: request.initials || '',
    middlename: request.middlename || '',
    lastname: request.lastname || '',
  };

  const canvasWidth = isSmallMobile ? 240 : isMobile ? 320 : 560;
  const canvasHeight = isMobile ? 200 : 400;

  return (
    <div className="document-sign-page">
      <Header title="DocumentSignPage.header" />
      <Container>
        <div className="document-sign">
          <div className="document-sign-canvas">
            <SignatureCanvas
              ref={ref => setCanvasRef(ref)}
              onEnd={() => onEnd()}
              penColor='#005c8d'
              canvasProps={{width: canvasWidth, height: canvasHeight, className: 'sigCanvas canvas'}} />
            <div>
              <ButtonGroup>
                <Button disabled={isEmpty} onClick={save} color="success">Bevestigen</Button>
                <Button onClick={cancel} color="danger">Annuleren</Button>
                <Button disabled={isEmpty} onClick={clear} color="primary">Wissen</Button>
              </ButtonGroup>
            </div>
          </div>
          <div className="document-sign-content">
            <ContentView id="signature.description" data={data} />
          </div>
        </div>
      </Container >
    </div >
  );
});
