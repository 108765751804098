import common from './common.json';
import forms from './forms.json';
import home from './home.json';
import purchase from './purchase.json';
import request from './request.json';
import reviews from './reviews.json';

const Content = {
  ...common,
  ...forms,
  ...home,
  ...purchase,
  ...request,
  ...reviews,
};

export default Content;
