import React from 'react';

import './HowItWorksStep.scss';

interface IHowItWorksStepProps {
  index: number;
  icon: string;
  text: string;
}

export const HowItWorksStep = ({ index, icon, text }: IHowItWorksStepProps) => (
  <div className="how-it-works-step">
    <div className="how-it-works-step-component">
      <img src={`images/${icon}`} alt="coin" />
      <h3 className="step">
        Stap
        {index}
      </h3>
      <div className="text">{text}</div>
    </div>
  </div>
);
