import React from 'react';

import { IInputFieldProps } from '@cashnu/forms';
import { ChoiceField } from './ChoiceField';

import './GenderField.scss';

const options = ['Man', 'Vrouw', 'Geen opgave'];

export const GenderField = ({ id, ...rest }: IInputFieldProps) => (
  <ChoiceField id="gender" options={options} className="gender-field" {...rest} />
);
