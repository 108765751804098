import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';

import "./HowItWorksBar.scss";

export const HowItWorksBar = () => (
  <div className="how-it-works-bar">
    <h3>Binnen enkele uren geld op je rekening</h3>
    <Link to="/how-it-works">
      <Button type="button">Lees meer</Button>
    </Link>
  </div>
);
