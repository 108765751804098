import React from 'react';
import { Link } from 'react-router-dom';
import YouTube from 'react-youtube';
import { ContentView } from '@cashnu/headless-content';

import "./InstructionsBar.scss";

const InstructionVideo = ({ width, height }) => (
  <YouTube
    videoId="fKhLqFeh-IE"
    title="CashNu instructie"
    className="video-player"
    opts={{
      height,
      width,
      playerVars: {
        autoplay: 0,
      },
    }}
  />);

export const InstructionsBar = () => (
  <div className="instructions-bar">
    <div className="text">
      <ContentView id="video-instruction" />
      <Link to="/how-it-works" className="btn btn-primary mt-3">
        Lees meer
      </Link>
    </div>
    <div className="video video-lg">
      <InstructionVideo width={640} height={360} />
    </div>
    <div className="video video-sm">
      <InstructionVideo width={360} height={200} />
    </div>
  </div>
);
