import React from 'react';

import { ContentPage, IRequestComponentProps, withRequest } from '../../components';

export const SignatureSavedPage = withRequest(({ request }: IRequestComponentProps) => {
  if (!request) return null;

  const data = {
    initials: request.initials || '',
    middlename: request.middlename || '',
    lastname: request.lastname || '',
  };

  return (
    <ContentPage
      id="signature-saved"
      data={data}
      title="SignatureSavedPage.header"
    />
  );
});
