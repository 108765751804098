import React from 'react';
import { Container } from 'reactstrap';
import { Header } from '@cashnu/services';

import { HowItWorksStep } from './HowItWorksStep';
import steps from './HowItWorksSteps.json';

import './HowItWorksPage.scss';

const Steps = () => <>{steps.map((step, index) => <HowItWorksStep key={step.icon} index={index + 1} {...step} />)}</>;

export const HowItWorksPage = () => (
  <div className="how-it-works-page">
    <Header title="HowItWorksPage.header" />
    <Container>
      <h1>Met CashNu kunt u zeer snel over extra geld beschikken! Vaak al binnen 4 uur!</h1>
      <p>Hoe kan dit? Volg simpelweg onderstaande stappen</p>
      <div className="steps">
        <Steps />
      </div>
    </Container>
  </div>
);
