import React, { ReactElement } from 'react';
import { Container } from 'reactstrap';

import { ContentView } from '@cashnu/headless-content';
import { Header } from './Header';

interface IContentPageProps<TData extends object> {
  id: string;
  data?: TData;
  title: string;
}

export const ContentPage = <TData extends object, >({ id, data, title }: IContentPageProps<TData>): ReactElement => (
  <>
    <Header title={title} />
    <Container>
      <ContentView id={id} data={data} />
    </Container>
  </>
);
