import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Container } from 'reactstrap';
import moment from 'moment';

import './Footer.scss';

export const Footer = () => (
  <div className="footer-wrapper">
    <div id="footer" className="footer-1">
      <div className="footer-main">
        <Container>
          <div className="row">
            <div className="col-lg-4">
              <div className="logo">
                <img src="/images/logo.svg" className="image wp-image-3148  attachment-full size-full" alt="" loading="lazy" style={{ maxWidth: '100%', height: 'auto' }} height="76.167" width="196.167" />
              </div>
              <div className="mt-2">
                <div className="textwidget">
                  <p><FormattedMessage id="Footer.description" /></p>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div id="nav_menu-3" className="widget widget_nav_menu">
                <h3 className="widget-title">Informatie</h3>
                <div className="menu-footer22-container">
                  <ul id="menu-footer22" className="menu">
                    <li><a href="/contact">Contact</a></li>
                    <li><a href="https://cdn.cashnu.nl/pdf/Algemene voorwaarden - Moneco 2022.pdf" target="_blank" rel="noreferrer">Algemene voorwaarden</a></li>
                    <li><a href="https://cdn.cashnu.nl/pdf/Privacybeleid - Moneco 2022.pdf" target="_blank" rel="noreferrer">Privacyverklaring</a></li>
                    <li><a href="/how-it works">Hoe werkt het</a></li>
                    <li><a href="/product">Start aanvraag</a></li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div id="text-4" className="widget widget_text">
                <h3 className="widget-title">Contact Details</h3>
                <div className="textwidget">
                  <div className="property">
                    <div className="label">Telefoon:</div>
                    <div className="value"><a href="tel:0108504855">+31 85 401 65 07</a></div>
                  </div>
                  <div className="property">
                    <div className="label">E-Mail:</div>
                    <div className="value"><a href="mailto:support@cashnu.nl">support@cashnu.nl</a></div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </Container>
      </div>

      <div className="footer-bottom">
        <div className="container">

          <div className="text-center">
            <div className="copyright"><FormattedMessage id="Footer.copyright" values={{ year: moment().year() }} /></div>
          </div>
        </div>
      </div>
    </div>
  </div>
);
