import React, { MouseEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'reactstrap';
import { formatMoney, Header } from '@cashnu/services';

import { ContentView } from '@cashnu/headless-content';
import { IRequestComponentProps, withRequest } from '../../components';

import './RequestDeliveryPage.scss';

export const RequestDeliveryPage = withRequest(({ request }: IRequestComponentProps) => {
  const navigate = useNavigate();

  if (request === undefined) return null;

  const { requestId: requestCode } = request;

  const onSale = <T extends unknown>(event: MouseEvent<T>) => {
    event.preventDefault();
    event.stopPropagation();
    navigate(`/request/form/${requestCode}`);
  };

  const onDelivery = <T extends unknown>(event: MouseEvent<T>) => {
    event.preventDefault();
    event.stopPropagation();
    navigate(`/purchase/${requestCode}`);
  };

  const productData = {
    productTitle: request.product!.title,
    purchasePrice: formatMoney(request.product!.purchasePrice),
    salesPrice: formatMoney(request.product!.salesPrice),
    shippingCosts: formatMoney(request.product!.shippingCosts),
  };

  return (
    <div className="delivery-page">
      <Header title="DeliveryPage.header" />
      <div className="types">
        <div className="type type-sale type-info-left" onClick={onSale}>
          <div className="type-info">
            <ContentView id="product-sale" data={productData} />
          </div>
          <div className="type-display">
            <h3>Munt direct doorverkopen</h3>
            <img src="/images/sale.png" alt="sale" />
            <Button color="primary">
              Doorverkopen
            </Button>
          </div>
        </div>
        <div className="type type-delivery type-info-right" onClick={onDelivery}>
          <div className="type-info">
            <ContentView id="product-delivery" data={productData} />
          </div>
          <div className="type-display">
            <h3>Munt thuis bezorgen</h3>
            <img src="/images/delivery.png" alt="delivery" />
            <Button color="primary">
              Bezorgen
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
});
