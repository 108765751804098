import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container } from 'reactstrap';
import {
  Header,
  STATE_REQUEST_ADDRESS_SAVED,
  STATE_REQUEST_COMMITTED
} from '@cashnu/services';
import {
  useErrorBoundary,
  useServices,
} from '@cashnu/services';
import { ContentView } from '@cashnu/headless-content';
import { Product, withRequest } from '../../components';
import { EntryForm } from './EntryForm';

import './RequestInputPage.scss';

export const RequestAddressForm = withRequest(({ requestCode, request, loading, section }) => {
  const { requestService } = useServices();
  const { throwError } = useErrorBoundary();
  const navigate = useNavigate();
  const [saving, setSaving] = useState(false);

  const saveConsumer = async (consumer) => {
    const newRequest = Object.assign({
      requestId: requestCode,
    }, consumer);

    setSaving(true);

    try {
      await requestService.saveAddress(newRequest);
      navigate(`/request/identification/${requestCode}`);

    } catch (e) {
      throwError(e);
      setSaving(false);
    }
  }

  const onSubmit = (consumer) => {
    const { state } = request;

    if (state >= STATE_REQUEST_ADDRESS_SAVED) {
      // Goto ready
      navigate(`/request/identification/${requestCode}`);
    } else {
      // Save
      saveConsumer(consumer);
    }
  }

  const onPrevious = (consumer) => {
    switch (section) {
      case 2:
        navigate(`/request/form/${requestCode}`);
        break;

      case 3:
        navigate(`/request/address/${requestCode}`);
        break;

      default:
        break;
    }
  }

  if (loading === true || request === undefined) return null;

  const { product } = request;

  return (
    <div className="confirm-page">
      <Header title="RequestAddressForm.header" />
      <div className="page-content">
        <Product {...product} showShipping={false} />

        <div className="entry-form">
          <Container>
            <ContentView id="address-form" className="mt-4 page-info" />
            <EntryForm
              section={1}
              disabled={saving || request.state >= STATE_REQUEST_COMMITTED}
              request={request}
              onSubmit={onSubmit}
              onPrevious={onPrevious}
              submitAllowed={!saving}
            />
          </Container>
        </div>
      </div>
    </div >
  );
});
