import React, { useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import { FileLoadField as SharedFileField } from '../components/Form';

import './FileField.scss';

const STATE_BLANK = 'blank';
const STATE_SAVING = 'saving';
const STATE_SAVED = 'saved';

export const FileField = ({ id, save, saved: isSaved, ...props }) => {
  const [saving, setSaving] = useState(false);
  const [saved, setSaved] = useState(isSaved);

  const state = useMemo(() => {
    if (!saving) {
      return (saved) ? STATE_SAVED : STATE_BLANK;
    } else {
      return STATE_SAVING;
    }
  }, [saved, saving]);

  const onChange = useCallback(async (id, files, _, clear) => {
    try {
      setSaving(true);

      const savedSuccessfully = await save(id, files);
      if (!savedSuccessfully) {
        clear();
      }
      setSaved(savedSuccessfully);
    } finally {
      setSaving(false);
    }
  }, [save]);

  return (
    <div className={classNames('file-field', state)}>
      {state === STATE_SAVING ? <div className="icon icon-blue"><i className="fas fa-spinner fa-spin"></i></div> : null}
      {state === STATE_SAVED ? <div className="icon icon-success"><i className="fa fa-check"></i></div> : null}
      <SharedFileField id={id} disabled={state !== STATE_BLANK} {...props} onChange={onChange} />
    </div>
  );
}
