import React, { useCallback, useMemo, useState } from 'react';
import Dropzone from 'react-dropzone';

import './FileField.scss';

export const FileLoadField = ({ id, onChange, children, disabled, maxFiles }) => {
  const [files, setFiles] = useState([]);
  const [valid, setValid] = useState(false);

  const thumb = useMemo(() => {
    if (files.length === 0) return null;
    const file = files[0]; 
    return (
      <div className="thumb">
        <div className="img" style={{ backgroundImage: `url(${file.preview})` }}></div>
      </div>
    );
  }, [files]);
  
  const validate = () => (files && files.length);

  const clear = useCallback(() => {
    setFiles([]);
  }, []);

  const drop = (acceptedFiles) => {
    const files = acceptedFiles.map(file => Object.assign(file, {
      preview: URL.createObjectURL(file)
    }));
    setFiles(files);
    const newValid = validate ? validate(id, files) : (files && files.length);
    if (valid !== newValid) setValid(newValid);
    // this.props.set(id, files, newValid);
    if (onChange) {
      onChange(id, acceptedFiles, newValid, clear);
    }
  }

  return (
    <Dropzone
      onDrop={drop}
      accept='image/jpeg, image/png, application/pdf'
      disabled={disabled}
      clear={clear}
      maxFiles={maxFiles}
      multiple={(!maxFiles || maxFiles > 1)}>
      {({getRootProps, getInputProps}) => (
        <div {...getRootProps({ className: `dropzone${disabled ? ' dropzone-disabled' : ''}` })}>
          <input {...getInputProps()} />
          <div className="drop-content">
            <div className="drop-info">{children}</div>
            {thumb}
          </div>
        </div>
      )}
    </Dropzone>
  );
}
