export const identificationTypeText = (identificationType?: number): string => {
  switch (identificationType) {
    case 0:
      return 'paspoort';
    case 1:
      return 'ID kaart';
    case 2:
      return 'rijbewijs';
    default:
      return 'identificatiebewijs';
  }
};

export const firstOrNull = <T>(x: T[] | undefined | null)
: T | null => {
  if (x === undefined || x === null || x.length === 0) return null;
  return x[0];
};

interface IIssuer {
  iban: string;
}

export const extractIssuer = (bankaccount?: string, issuers?: IIssuer[])
  : IIssuer | null => {
  if (!bankaccount) return null;

  const iban = /^(\w{2})(\d{2})(\w{4})(\d{10})$/
    .exec(bankaccount.toUpperCase().replace(/\s/g, ''));
  if (!iban) return null;

  return firstOrNull((issuers || []).filter((i) => i.iban === iban[3]));
};
