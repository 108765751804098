import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container } from 'reactstrap';
import {
  Header,
  IConsumer,
  STATE_REQUEST_COMMITTED,
  STATE_REQUEST_IDENTIFICATION_SAVED,
  useErrorBoundary,
  useServices,
} from '@cashnu/services';

import { ContentView } from '@cashnu/headless-content';
import { withRequest } from '../../components';
import { IAppServices } from '../../components/IAppServices';

import { EntryForm } from './EntryForm';

import './RequestInputPage.scss';

export const RequestIdentificationForm = withRequest(({
  requestCode,
  request,
  loading,
}) => {
  const { requestService } = useServices<IAppServices>();
  const { throwError } = useErrorBoundary();
  const navigate = useNavigate();
  const [saving, setSaving] = useState(false);

  const saveConsumer = async (consumer: IConsumer): Promise<void> => {
    const newRequest = { requestId: requestCode, ...consumer };

    setSaving(true);

    try {
      await requestService.saveIdentification(newRequest);
      navigate(`/request/documents/${requestCode}`);
    } catch (e) {
      throwError(e as Error);
      setSaving(false);
    }
  };

  const onSubmit = (consumer: IConsumer): void => {
    if (request === undefined) return;
    const { state } = request;

    if (state >= STATE_REQUEST_IDENTIFICATION_SAVED) {
      // Goto ready
      navigate(`/request/documents/${requestCode}`);
    } else {
      // Save
      saveConsumer(consumer);
    }
  };

  const onPrevious = (): void => {
    navigate(`/request/address/${requestCode}`);
  };

  if (loading === true || request === undefined) return null;

  // const { product } = request;

  return (
    <div className="confirm-page">
      <Header title="RequestIdentificationForm.header" />
      <div className="page-content">
        {/* <Product {...product} showShipping={false} readonly shippingCosts={0} /> */}

        <div className="entry-form">
          <Container>
            <ContentView id="request-input" className="mt-4 page-info" />
            <EntryForm
              section={2}
              disabled={saving || request.state >= STATE_REQUEST_COMMITTED}
              request={request}
              onSubmit={onSubmit}
              onChange={() => {}}
              onPrevious={onPrevious}
              submitAllowed={!saving}
            />
          </Container>
        </div>
      </div>
    </div >
  );
});
