import React, { ReactElement } from 'react';
import { Header, IException } from '@cashnu/services';

import { useErrorContext } from '../../contexts';

import './ErrorPage.scss';
import { Container, Row } from 'reactstrap';
import { ContentView } from '@cashnu/headless-content';

export const ErrorPage = (): ReactElement => {
  const { error } = useErrorContext();
  let title = 'Error';

  if ((error as IException)?.name === 'FailedToFetchException')
    title = 'API Communication Error';

  return (
    <div className='error-page'>
    <Header title={title} />
      <Container>
        <Row>
          <ContentView id="error-guide" />
        </Row>
        <Row>
          <div className="details">
            <p>Details:</p>
            <ul>
              <li>{(error as IException)?.name}</li>
              <li>{error?.message}</li>
            </ul>
          </div>
        </Row>
      </Container>
    </div>
  );
};
