import React, { ReactElement, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IRequest, useErrorBoundary, useServices } from '@cashnu/services';
import { Header } from '@cashnu/services';

import { IRequestIdentifiers, Product, ProductProps } from '../../components';

import products from './Products.json';
import { IAppServices } from '../../components/IAppServices';

import './ProductPage.scss';

const typedProducts: ProductProps[] = [];
Object.assign(typedProducts, products);

interface IProductsProps {
  onClick: (product: ProductProps) => void;
  readonly: boolean;
}

const Products = ({ onClick, readonly }: IProductsProps) => (
  <>
    {typedProducts.map((product) => <Product key={product.product} onClick={(event: Event) => onClick(product)} {...product} readonly={readonly} />)}
  </>
);

export const ProductPage = (): ReactElement => {
  const [creating, setCreating] = useState(false);
  const navigate = useNavigate();
  const { requestService } = useServices<IAppServices>();
  const { throwError } = useErrorBoundary();

  const createRequest = (product: ProductProps): void => {
    if (creating) return;

    setCreating(true);

    requestService.create({ product } as IRequest)
      .then(
        (request: IRequestIdentifiers) => {
          navigate(`/request/delivery/${request.requestId}`);
        },
        (error: Error) => {
          setCreating(false);
          throwError(error);
        },
      );
  };

  return (
    <div className="product-page">
      <Header title="ProductPage.header" />
      <div className="products">
        <Products onClick={createRequest} readonly={creating} />
      </div>
    </div>
  );
};
